.navbar {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 54px;

  &-inverse {
    background-color: #1a1c22;
    border-color: #1a1c22;
  }

  & > .container-fluid {
    padding-left: 0;
  }
}

.navbar-nav {
  align-items: center;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  padding: 7px 15px;
  color: #cfd2da;
}

.nav-tabs .nav-link.active {
  color: #cfd2da;
  background-color: #434857;
  border: 1px solid #434857;
  border-bottom-color: transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.navbar-dark {
  & .navbar-brand {
    color: #cfd2da;
    @include transition($nav-link-transition);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  & .navbar-nav .nav-link {
    color: #cfd2da;
  }

  & .navbar-nav .nav-link:hover,
  & .navbar-nav .nav-link:focus {
    color: #ffffff;
    text-decoration: none;
  }
}

.navbar-brand {
  padding: 18px 18px 18px;
  font-size: 18px;
  line-height: 21px;
  height: 54px;
  margin-right: 0;

  &>img {
    display: block!important;
  }
  // height: 54px;

  @media (min-width: 992px) {
    .navbar > .container &,
    .navbar > .container-fluid & {
      margin-left: -18px;
    }
  }
}

.navbar-expand .navbar-nav .nav-link {
  padding: 18px 15px;
}

.dropdown-toggle::after {
  vertical-align: middle;
}

.mr15 a {
  color: #cfd2da;
  @include transition($nav-link-transition);

  &:hover,
  &:focus {
    color: #ffffff;
  }
}
