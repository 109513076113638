.buildPlate-lineItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2E303B;
  border: 1px solid #464646;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 5px;

  &DetailsPiecesAlertBtn {
    background: none;
    border: none;
    padding: 0;
    margin-top: 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    transition: .3s;
    box-shadow: unset !important;

    &:hover {
      color: #eeeeee;
    }
  }

  &Snapshot {
    margin-right: 10px;
    position: relative;
    border-radius: 7px;
    height: 85px;
    max-height: 85px;
    max-width: 100px;
    object-fit: cover;
    object-position: center;
    width: 100px;

    &Content {
      position: relative;
      border-radius: 7px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;

      &Image {
        height: 85px;
        max-height: 85px;
        max-width: 100px;
        object-fit: cover;
        object-position: center;
        width: 100px;
        border-radius: 7px;
      }
    }
  }

  &LeftContent {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &RightContent {

    &PiecesCount {
      font-weight: 500;
      font-size: 12px;
      color: #d9d9d9;
    }
  }
}

.production-change-checkbox > input {
  margin: 0 !important;
}
