.btn {
  font-size: 14px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.btn-primary {
  color: #ffffff;
  background-color: #1ca8dd;
  border-color: #1997c6;
  @include transition($btn-transition);

  &:hover,
  &:focus {
    background-color: #1686b0;
    border-color: #1997c6;
  }
}

.btn-link {
  color: #1ca8dd;
  text-decoration: none;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs {
  padding: 1px 5px!important;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.block {
  display: block;
  width: 100%;
}


.btn-default {
  color: #51586a;
  background-color: #fff;
  border-color: #e6e6e6;
  border-radius: 4px;
  font-weight: 400;

  &:hover,
  &:focus {
    color: #51586a;
    background-color: #e6e6e6;
    border-color: #c7c7c7;
  }
}
