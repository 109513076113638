.ResourceCard {
  background: #3B3F4B;
  border: 1px solid #434857;
  border-radius: 5px;
  padding: 20px;

  &ListSmall {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)) !important;
  }

  &SmallTopView {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &Material {
    &Type {
      color: #C8C8C8;
      font-weight: 100;
      font-size: 12px;
    }
  }

  &SmallResources {
    display: flex;
    height: 100%;
    max-height: 37px;
    justify-content: space-between;
    width: 100%;
  }

  &List {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) !important;

    &Cards {
      margin-top: 15px;
    }

    &Location {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    &OrderList {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 5px;
      margin-top: 15px;
    }

    &SubLocation {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }

  &Resources {
    display: flex;
    height: 100%;
    max-height: 37px;

    &Data {
      max-width: 100px;
    }
  }

  &Printer {

    &Status {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: right;
      white-space: nowrap;
    }

    &Name {
      width: 130px;
      max-width: 130px;
    }

    &Type {
      width: 130px;
      max-width: 130px;
      font-size: 12px;
    }
  }

  &ModelerStatuses {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    max-height: 37px;
  }

  &TopView {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &BottomView {
    display: flex;
    justify-content: space-between;

    &Card {
      background: #434857;
      padding: 10px;
      border-radius: 5px;
    }
  }

  &Material {
    &Name {
      width: 115px;
      max-width: 115px;
    }
  }

  &Batch {
    &Quantity {
      max-width: 85px;
      display: inline-block;
    }
  }

  &CurrentRunNameShort {
    max-width: 165px;
  }

  &CurrentRunNameLong {
    max-width: 300px;
  }

}

.LineItemCard {
  background-color: #3B3F4B;
  border: 1px solid #434857;
  border-radius: 5px;
  padding: 10px;
  position: relative;

  &Icon {
    width: 15.5px;
  }

  &Arrow {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 15px;
  }

  &PieceSnapshot {
    align-items: center;
    border-radius: 50% !important;
    display: flex;
    height: 71px;
    max-height: 71px;
    justify-content: center;
    max-width: 71px;
    overflow: hidden;
    position: relative;
    transition: 0.3s;
    width: 71px;
    min-width: 71px;

    &Tooltip {
      height: 90px;
      min-height: 90px;
      max-height: 90px;
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      overflow: hidden;
      position: relative;
      transition: 0.3s;
      object-fit: contain;
      border-radius: 3px;

      &Preview > div.tooltip-arrow + div.tooltip-inner {
        margin: 0 10px !important;
        padding: unset !important;
      }
    }
  }

  &SwitchBtn {
    display: flex;
    max-width: 30px;
    max-height: 30px;
    background-color: #434857 !important;
    border-radius: 50%;
    transition: .2s ease-in-out;
    padding: 8px 10px !important;
    border: none !important;

    & > svg {
      color: #d0d2da !important;
      font-size: 14px;
      vertical-align: middle;
    }

    &:disabled {
      cursor: not-allowed !important;
      background-color: #5B5B5B !important;
    }

    &:hover {
      cursor: pointer;
      background-color: #61687c !important;
    }
  }
}
