.run-status__selector {
    .picky button.picky__input {
        height: 35px;
        width: 178px;

        span.picky__placeholder {
            line-height: 24px;
        }
    }

    .picky__dropdown {
        width: max-content;

        .option {
            height: 35px;
            line-height: 24px;
        }
    }
}