.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid darken(#f5f5f5, 7%);
  border-radius: 4px;
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.05));
  blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
  }

  &-well {
    text-align: center;
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: white;
    border: 2px solid white;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  &-lg {
    padding: 24px;
    border-radius: 6px;
  }

  &-sm {
    padding: 9px;
    border-radius: 3px;
  }
}
