.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  // &.fade .modal-dialog {
  //   @include translate(0, -25%);
  //   @include transition-transform(0.3s ease-out);
  // }
  // &.in .modal-dialog {
  //   @include translate(0, 0);
  // }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #999; //old browsers fallback (ie8 etc)
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  // @include box-shadow(0 3px 9px rgba(0, 0, 0, 0.5));
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  // Fade for backdrop
  // &.fade {
  //   @include opacity(0);
  // }
  // &.in {
  //   @include opacity(0.5);
  // }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  // @include clearfix;
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: 1.428571429;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: 15px;
}

// Footer (for actions)
.modal-footer {
  padding: 15px;
  text-align: right; // right align buttons
  border-top: 1px solid #e5e5e5;
  // @include clearfix; // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    margin-left: 5px;
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: 768px) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  // .modal-content {
  //   @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
  // }

  // Modal sizes
  .modal-sm {
    width: 300px;
  }

  .modal-md {
    width: 500px;
  }

  .modal-xl {
    width: 100%!important;
    max-width: 80vw;
  }
}

// Scale up the modal
@media (min-width: 862px) {
  .modal-lg {
    width: 800px;
    margin: 30px auto;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
  .modal-md {
    width: 700px;
  }
}

@media (min-width: 1200px) {
  .modal-lg {
    width: 1100px;
  }
  .modal-md {
    width: 850px;
  }
}
