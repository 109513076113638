@import '../dashboard-dark/variables-custom';
@import '../dashboard-dark/mixins/main';

.react-tag-input {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 2.375em;
  padding: 0.1875em 0.375em;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  background-color: $input-bg;
  color: $gray-base;
  border-radius: $input-border-radius;
  border: 1px solid $input-border;
}
.react-tag-input * {
  box-sizing: border-box;
}
.react-tag-input > * {
  margin: 0.1875em;
}
.react-tag-input__input {
  width: auto;
  flex-grow: 1;
  height: 1.875em;
  padding: 0 0 0 0.1875em;
  margin: 0 0.1875em;
  font-size: 1em;
  line-height: 1;
  background: transparent;
  color: $input-color;
  border: none;
  border-radius: 3px;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
}
.react-tag-input__input::placeholder, .react-tag-input__input:-moz-placeholder, .react-tag-input__input:-ms-input-placeholder, .react-tag-input__input::-moz-placeholder, .react-tag-input__input::-webkit-input-placeholder {
  color: $input-color;
}
.react-tag-input__input:focus {
  border: none;
}
.react-tag-input__tag {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  line-height: 1;
  background: #e1e1e1;
  border-radius: 3px;
}
.react-tag-input__tag__content {
  outline: 0;
  border: none;
  white-space: nowrap;
  padding: 0 0.46875em;
}
.react-tag-input__tag__remove {
  position: relative;
  height: 2em;
  width: 2em;
  font-size: 0.85em;
  cursor: pointer;
  background: #d4d4d4;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.react-tag-input__tag__remove:before, .react-tag-input__tag__remove:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: ' ';
  height: 0.9em;
  width: 0.15em;
  background-color: $gray-base;
}
.react-tag-input__tag__remove:before {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.react-tag-input__tag__remove:after {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.react-tag-input__tag__remove-readonly {
  width: 0;
}
.react-tag-input__tag__remove-readonly:before, .react-tag-input__tag__remove-readonly:after {
  content: '';
  width: 0;
}

.modal-dialog {
  .react-tag-input {
    background-color: $modal-content-bg;
    border: 1px solid $modal-content-border-color;
  }

  .react-tag-input__input {
    color: #51586a;

  }
}
