.workflowStepsModalWindow {
  min-width: 800px !important;

  &LeftSide {
    position: relative;
    width: 30%;
    padding-left: 0!important;
    padding-right: 0!important;
  }

  &ShortenedText {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .modal-dialog {
    width: 800px !important;
    max-width: 800px !important;
    min-width: 800px !important;
  }

  &HeaderTitle {
    font-size: 13px;
    margin: 0 0 0 15px;
  }

  &Title {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    margin-left: 5px !important;
  }

  &IconsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > button:first-child {
      margin-right: 5px;
    }
  }

  &Button {
    padding: 2px 8px !important;
    font-size: 16px;

    &Icon {
      height: 21px !important;
    }
  }


  &StepSelectData {
    overflow-y: auto;
    max-height: 400px;

    &Title {
      position: sticky;
      top: 0;
      margin-bottom: 0 !important;
      background: #fff;
      height: 100%;
      font-weight: 400;
      padding: 0 0 5px 5px;
    }

    &Disabled {
      pointer-events: none !important;
      cursor: not-allowed !important;
    }
  }

  &StepSelectActive {
    background-color: #4CA7DD;
    color: #fff;
  }

  &StepSelect {
    white-space: nowrap;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 3px 0 0 3px;
    max-width: 265px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &Icon {
      margin-right: 5px;
      text-align: center;
      width: 14px;
      height: 14px;
    }

    &:hover {
      background-color: #4CA7DD;
      color: #fff;
    }
  }

  &StepModalNoBorder {
    border: none !important;
    padding: 0;
  }

  &StepModalFooter {
    & > button {
      padding: 6px 12px;
    }
  }

  &CustomizeButton {
    position: absolute;
    bottom: 0;
    width: 98%;
    padding: 5px;
    margin: 0!important;
  }
}
