.list-group {
  &-item {
    padding: 10px 15px;
    color: #cfd2da;
    background-color: transparent;
    border: 1px solid #434857;
  }
}

.list-group-quote {
  padding: 10px 15px;
  color: #51586a!important;
  background-color: transparent;
  &:hover {
    background-color: none!important
  }
}
