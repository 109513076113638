.line-item-quote-modal .modal-content {
  background-color: #2E303B;
  color: #fff;
}

.line-item-quote-modal .modal-header, .line-item-quote-modal .modal-footer {
  background-color: #2E303B;
  color: #fff;
}

.line-item-quote-modal .modal-header {
  border-bottom: none;
  margin-bottom: 15px;
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
}

.line-item-quote-modal .modal-title {
  color: #fff;
  position: relative;
  display: inline-block;
}

.line-item-quote-modal .modal-title:after {
  content: "";
  display: block;
  width: 24.8%;
  height: 1px;
  background: #7B7B7B;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.line-item-quote-modal .modal-body {
  padding-left: 25px;
  padding-right: 25px;
}

.line-item-quote-modal .bl-light {
  border-left: 1px solid #434857;
}

.line-item-quote-modal-step-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;

  & > svg {
    font-size: 24px;
  }
}

.line-item-quote-modal .modal-footer {
  border-top: none;
}

.line-item-quote-modal-model {
  align-items: center;
  background-color: #d1d1d1;
  border-radius: 7px;
  color: #909090;
  height: 75px;
  max-height: 75px;
  max-width: 100px;
  object-fit: cover;
  object-position: center;
  width: 100px;
  margin-right: 10px;

  & > img {
    border: 2px solid #9a9a9a;
    border-radius: 7px !important;
  }
}

.line-item-quote-modal-title-text {
  font-weight: 400;
  margin-top: 3px;
  font-size: 17px;
  margin-bottom: 0;
  margin-left: 6px;

  &-title {
    max-width: 300px;
  }
}

.line-item-quote-modal-button {
  color: rgb(255 255 255 / 70%);
  font-size: 25px;
  cursor: pointer;
  outline: none;
  transition: 0.3s;

  &:hover {
    color: #fff;
  }
}

.line-item-quote-modal-search-button {
  font-size: 22px !important;
  margin-left: 10px;
}

.line-item-quote-modal .input-group-addon,
.line-item-quote-modal  .form-control {
  color: #fff !important;
  border-color: #585A60 !important;
  background: #434857 !important;
}

.line-item-quote-modal-separator {
  background-color: #D0D2DA;
  width: 40%;
  margin: 15px auto;
}

.line-item-quote-modal-no-model {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  object-position: center;
  border-radius: 7px;
  color: #909090;
  background-color: #d1d1d1;
  font-size: 14px;
  height: 100%;
}

.line-item-quote-modal-model-fetching {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.line-item-quote-modal-line-item-btn {
  background-color: #434857 !important;
  border-radius: 50%;
  transition: .2s ease-in-out;
  padding: 8px 12px !important;
  border: none !important;

  & > svg {
    color: #2E303B !important;
    font-size: 20px;
    vertical-align: middle;
  }

  &:disabled {
    cursor: not-allowed !important;
    background-color: #5B5B5B !important;
  }

  &:hover {
    cursor: pointer;
    background-color: #61687c !important;
  }
}

.line-item-quote-modal-custom-gutter {
  --bs-gutter-x: 3.5rem !important;
}

.line-item-quote-modal--card {
  border-color: #54595E !important;
}

.line-item-quote-modal--card-lite {
  border-color: #414345 !important
}

.line-item-quote-modal--card-header {
  background-color: #434857;
  color: #fff;
}

.line-item-quote-modal--card-header-quotePreview {
  background-color: #2E303B;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 15px 0;
}

.line-item-quote-modal--card-body {
  background-color: #2E303B;
}

.line-item-quote-modal--toggle-button-sm {
  color: #fff;
  font-size: 11px !important;
  line-height: 1 !important;
}

.line-item-quote-modal--body-scroll {
  overflow-y: auto;
  max-height: calc(100vh - 180px);
}

.line-item-quote-modal .form-check-label {
  color: #fff !important;
}

.line-item-quote-modal-input {
  width: 100%;
  background-color: unset;
  border-top: none;
  border-bottom: 1px solid #585A60;
  border-right: 0;
  border-left: 0;
  outline: none;
  color: #fff;
  box-shadow: none;

  /* Hide the number input arrows */
  -moz-appearance: textfield; /* Firefox */
}

.line-item-quote-modal-input::-webkit-outer-spin-button,
.line-item-quote-modal-input::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
  margin: 0;
}

.line-item-quote-modal h4 {
  color: #fff !important;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 15px;
}

.line-item-quote-modal .list-group-quote {
  color: #fff !important;
}

.line-item-quote-modal-col-dashed-line {
  border-right: 1px dashed #7B7B7B;
}

.line-item-quote-modal-summary {
  padding: 0 10px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .custom-scroll {
    max-height: calc(100vh - 190px);
  }
}

@media (min-width: 992px) {
  .custom-scroll {
    max-height: calc(100vh - 200px);
  }
}


@media (min-width: 1200px) {
  .custom-scroll {
    max-height: calc(100vh - 210px);
  }
}

@media (min-width: 1400px) {
  .custom-scroll {
    max-height: calc(100vh - 220px);
  }
}
