.table {
  //color: #cfd2da;
  border-color: #434857;

  > :not(caption) > * > * {
    padding: 0.8rem 0.8rem;
  }

  > thead {
    text-align: center;
    vertical-align: middle;
  }

  &-bordered > thead > tr > th,
  thead > td > th {
    border-bottom-width: 2px;
  }

  &-hover {
    > tbody > tr:hover > * {
      color: #cfd2da;
      background-color: #434857;
    }
  }
}
