// Additional color system
// $navbar-links-light-color: #cfd2da !default;

$spacer: 20px;
$spacer-x: $spacer;
$spacer-y: $spacer;


$font-family-sans-serif:  "Roboto","Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base:          14px;
$line-height-base:        1.428571429; // 20/14
$gray-base:               #252830;
$gray-dark:               lighten($gray-base, 20%);
$gray-darker:             lighten($gray-base, 13.5%);
$gray-light:              lighten($gray-base, 66.7%);
$body-bg:                 $gray-base;
$text-color:              $gray-light;
$page-header-border-color:$gray-darker;
$link-color:              darken(#428bca, 6.5%);
$link-hover-color:        darken($link-color, 15%);
$link-hover-decoration:   underline;
$btn-default-color:       $gray-dark;

$border-radius-base:      4px;
$border-radius-large:     6px;
$thumbnail-padding:       4px;
$thumbnail-bg:            $body-bg;
$thumbnail-border:        #ddd;
$thumbnail-border-radius: $border-radius-base;

$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px
$gray-lighter:            lighten(#000, 93.5%); // #eee
$hr-border:               $gray-lighter;











