.horizontal-slider {
    cursor: e-resize;
    margin: 0 5px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }
.slider-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #555;
  border: 5px solid #555;
  height: 20px;
  width: 10px;
  border-radius: 12px;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.slider-thumb.active {
  background-color: grey;
}
.slider-track {
  position: relative;
  background: #ddd;
}
.slider-track.slider-track-0 {
  background: #C0C0C0;
}
.horizontal-slider .slider-track {
  height: 20px;
}
.horizontal-slider .slider-thumb {
  width: 10px;
  outline: none;
  height: 20px;
}

/* 
Code to prevent <p> with contentEditable enabled
from spanning multiple lines. Simply add the 
'single-line' class name.
*/
[contenteditable="true"].single-line {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
} 
[contenteditable="true"].single-line br {
  display:none;

}
[contenteditable="true"].single-line * {
  display:inline;
  white-space:nowrap;
}