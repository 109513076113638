@import './variables.scss';
@import '../dashboard-dark/variables-custom.scss';

body.hawking {
  font-family: $hawking-font-family-base!important;
  font-weight: $hawking-font-weight-base!important;
  color: $hawking-text-color!important;
  background-color: $hawking-body-bg!important;
}

.hawking {
  .login-form-container {
    background-image: url("../../images/hawking_landing_page_large.jpg");
  }

  .login-form {
    background: rgba(255, 255, 255, .9);
  }

  .navbar-inverse {
    background-color: $hawking-navbar-inverse-bg;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-size: 18px!important;
    color: $hawking-headings-color;
  }


  hr {
    border-color: $hawking-gray-light;
  }

  .breadcrumb {
    display: none;
  }

  .hawking-logo-heading-text {
    font-size: 24px!important;
    margin-top: 21px!important;
    margin-bottom: 10.5px!important;
    color: #0F1318!important;
  }

  .form-control {
    background-color: $hawking-input-bg!important;
    color: $hawking-input-color!important;
    border-color: $hawking-input-border!important;
    &:disabled {
      background-color: $hawking-input-bg!important;
      color: $hawking-input-color!important;
      border-color: $hawking-input-border!important;
    }

  }

  .form-group {
    margin-bottom: 15px!important;
  }

  .input-group-addon {
    background-color: $hawking-input-bg;
  }

  .input-group-text {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #fff;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
    height: unset;
    background-color: #FAFAFF;
  }

  .jumbotron {
    background-color: $hawking-jumbotron-bg;
    border: 1px solid $hawking-jumbotron-border;
    color: $hawking-jumbotron-color;

    h1 {
      color: $hawking-jumbotron-color;
    }
  }

  .table {
    &-hover {
      > tbody > tr:hover > * {
        color: #000;
        background-color: #cfd2da;
      }
    }
  }

  table.table-bordered {
    border: none;
  }

  table > tbody > tr {
    color: #0F1318!important;
    border-bottom: 1px solid $hawking-table-border-color !important;
  }

  table > thead {
    text-align: left!important;
  }

  table > tbody > tr > td {
    border: none !important;
  }

  table > tbody > tr:hover {
    background-color: darken($hawking-gray-lighter, 5%) !important;
  }

  table > thead { border: none; }

  table > thead > tr {
    background-color: $hawking-table-heading-bg !important;
    border-color: $hawking-table-heading-bg !important;

    th {
      border-color: $hawking-table-heading-bg !important;
      color: $hawking-text-color;
    }
  }

  .modal-dialog {
    .modal-header,.modal-footer {
      border-color: darken($hawking-gray-lighter, 5%);
      display: flex;
      align-items: flex-start;
    }

    .modal-footer > .btn {
      padding: 6px 12px!important;
    }

    .modal-content {
      background-color: $hawking-modal-bg;
    }

    .picky button {
      background-color: $hawking-input-bg;
    }

    .picky__input--disabled {
      background-color: $hawking-disabled-button-bg!important;
      border-color: $hawking-disabled-button-bg!important;
      color: $hawking-disabled-button-color!important;
      cursor: not-allowed;
    }
  }

  .btn.btn-primary {
    background-color: $hawking-brand-primary!important;
    border-color: $hawking-brand-primary!important;
  }

  .btn.btn-primary:focus {
    outline-color: $hawking-slate-700;
  }

  .btn.btn-default {
    background-color: $hawking-body-bg!important;
    border-color: darken($hawking-gray-lighter, 5%) !important;
    color: #51586a!important;

    &:active {
      box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%)!important;
    }
  }

  .picky button {
    color: $hawking-text-color;
    background-color: $hawking-input-bg;
    border: 1px solid $hawking-input-border;
  }

  .btn-toolbar > button {
    padding: 6px 12px!important;
  }

  .btn-upload {
      padding: 6px 12px!important;
  }

  .btn.dark-gray-btn {
    background-color: $hawking-slate-500!important;
    border-color: $hawking-slate-500!important;
    padding: 10px 16px!important;
    font-size: 18px!important;
    border-radius: 6px!important;
    color: #fff!important;
    width: 100%!important;
  }

  .tabs-container > .nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
    color: #252830!important;

    &:hover {
      background-color: #cfd2da!important;
    }
  }

  .tabs-container > .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: #cfd2da!important;
    background-color: #cfd2da!important;
    color: #51586a!important;
  }

  .btn[disabled] {
    background-color: $hawking-disabled-button-bg!important;
    border-color: $hawking-disabled-button-bg!important;
    color: $hawking-disabled-button-color!important;
  }

  .panel.panel-primary {
    border: none;
    box-shadow: none;

    .panel-heading {
      border: none;
      background-color: inherit;
      color: $hawking-text-color;
      font-weight: 700;
      font-size: 16px;
    }

    .panel-body {
      background-color: $hawking-light-bg;
      border-radius: 6px;
      box-shadow: 0 1px 2px 1px rgba(0,0,0,0.16);
    }
  }

  .panel.panel-default {
    border: none;
    box-shadow: none;

    .panel-heading {
      border: none;
      background-color: inherit;
      color: $hawking-text-color;
      font-weight: 700;
      font-size: 16px;
    }

    .panel-body {
      background-color: $hawking-light-bg;
      border-radius: $hawking-border-radius;
      box-shadow: $hawking-box-shadow;
    }
  }

  .list-group {
    .list-group-item {
      border-color: $hawking-border;
      color: $hawking-text-color;
    }


    a.list-group-item:hover, div.list-group-item:hover, a.list-group-item:focus, div.list-group-item:focus, button.list-group-item:hover {
      background-color: $hawking-slate-50;
    }
  }

  .list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: $hawking-slate-50;
    color: $hawking-text-alt-color;
  }

  .card {
    background-color: $hawking-card-bg;
    border: none;
  }

  .card-header {
    border: none;
    color: #0F1318;
    font-weight: 700;
    font-size: 16px;
  }

  .dropdown-item {
     font-size: 14px
  }

  .card-title {
    color: $hawking-text-color;
    font-weight: bold;
  }

  .part-analysis-scenarios {
    .panel-body {
      padding: 0;
    }
  }

  .progress {
    background-color: $hawking-slate-100;

    .progress-bar {
      background-color: $hawking-slate-300;
    }
  }

  .fa-icon-style {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .hawking-forgot-password {
    color: $hawking-slate-500;
  }

  .hawking-username {
    border-radius: 3px 3px 0px 0px;
    line-height: 30px;
  }

  .hawking-password {
    border-radius: 0px 0px 3px 3px;
    line-height: 30px;
  }
}

.hawking-primary {
  color: $white!important;
  background-color: $hawking-brand-primary!important;
  border-color: $hawking-brand-primary!important;
  &:hover,
  &:hover:active,
  &:hover:focus,
  &:focus,
  &:active {
    color: $white!important;
    background-color: $hawking-slate-600!important;
    border-color: $hawking-slate-600!important;
  }
}

.hawking-secondary {
  color: $hawking-gray!important;
  background-color: $hawking-body-bg!important;
  border-color: $hawking-slate-100!important;
  &:hover,
  &:hover:active,
  &:hover:focus,
  &:focus,
  &:active {
    color: $hawking-gray!important;
    background-color: $hawking-slate-100!important;
    border-color: $hawking-slate-100!important;
  }
}

.authentise-pdm {

  #sidebar {
    background-color: #1a181887;

    a > svg > path {
      fill: #fff;
    }

    li.active {
      background-color: #282a2e;
    }

    &li,  a {
      color: #fff
    }
  }

  .fade.in {
    overflow-y: auto;
  }

  .navbar {
    background-color: #1a1c22;
  }

  .navbar-impersonate {
    background-color: #203455;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }

}

.hawking-badge-secondary {
  background-color: #cfd2da!important;
  color: #222429!important;
}

.hawking-badge-secondary-sm {
  background-color: #cfd2da!important;
  color: #222429!important;
  font-size: 75%!important;
}

.castor-status-badge {
  padding: 5px 10px !important;
  border-radius: 8px !important;
  color: #525868 !important;
  font-size: 12px !important;
  background: #F8F9FA !important;
  font-weight: 500 !important;
}

.castor-dropdown-toggle {
  &::after {
    display: none;
  }
}

.castor-btn {
  padding: 3px 10px !important;
}

