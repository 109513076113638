@import '../variables.scss';

#stanley-x-sidebar {
  position: fixed;
  left: 0;
  width: inherit;
  height: 100%;
  background-color: $stanley-sidebar-bg;
  border-right: 1px solid $stanley-sidebar-border;

  li {
    padding: 10px 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  li.active {
    background-color: $stanley-sidebar-active-bg;
  }

  a {
    color: $stanley-sidebar-color;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  a > svg > path {
    fill: $stanley-sidebar-color;
  }

  .active > a {
    color: $stanley-sidebar-active-color;
  }
  .active > a > svg > path {
    fill: $stanley-sidebar-active-color;
  }

  .collapsible-sidebar > li > a {
    padding-left: 10px;
  }

  @media (max-width: 767px) {
    /* style sidebar on small screen */
  }
}
