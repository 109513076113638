.stepper {
  display: table;
  width: 100%;
  margin: 0 auto;
  color: #FFFFFF;
}

.stepper-prev-count {
  font-size: 16px;
  margin-right: 15px;
  font-weight: 600;
  border: none;
  outline: none;
}

.stepper-next-count {
  font-size: 16px;
  margin-left: 15px;
  font-weight: 600;
  border: none;
  outline: none;
}

.stepper .step {
  &.active {
    border: 1px solid black;
    border-radius: 4px;
  }
  display: table-cell;
  position: relative;
  padding: 24px;
  border: none;
  // Adding transparent top/bottom border to sync with .active bordered item.
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  outline: none;
  transition: 0.35s;
}

.stepper .step:hover {
  background-color: #434857;
}

.stepper .step:active {
  background-color: #555;
}

.stepper .step.disabled {
  cursor: not-allowed;
}

.stepper .step .step-circle {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: #696969;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
}

.stepper .step .step-title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
}

.stepper .step .step-title,
.stepper .step {
  text-align: center;
}

.stepper .step-title {
  font-weight: 600;
}

.stepper .step:first-child .step-bar-left,
.stepper .step:last-child .step-bar-right {
  display: none;
}

.stepper .step .step-bar-left,
.stepper .step .step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 1px solid #DDDDDD;
}

.stepper .step .step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}

.stepper .step .step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}

.stepper .tooltip-content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .stepper {
    display: flex;
    flex-direction: column;
  }
  .stepper-prev-count {
    display: none;
  }
  .stepper-next-count {
    display: none;
  }
  .stepper .step {
    display: flex;
  }
  .stepper .step .step-bar-left,
  .stepper .step .step-bar-right {
    display: none
  }
  .stepper .step .step-circle {
    text-align: start;
    margin: 0;
    margin-right: 15px;
  }
  .stepper .step .step-title {
    margin-top: 0;
  }
}
