.textarea-autocomplete-dropdown {
    top: 10px !important;
    max-height: 200px;
    overflow-y: auto;

    .rta__list {
        border: none;
        box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
        background: transparent;
    }


    .list-item {
        color: white;

        .user-item__email {
            font-weight: bold;
            font-size: 14px;

            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
        }
        .user-item__name {
            font-size: 12px;

            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
        }
    }
}

.user-searcher__toggler {
    cursor: pointer;
    font-size: 17px;
}

.select-user__dropdown {
    .dropdown-menu.show {
        max-height: 170px;
        overflow-y: auto;
        background-color: #212529;
        background-color: #2e3439;
        padding-bottom: 4px;
        box-shadow: 0 2px 5px #00000080;

        .select-user__input{
            margin: 12px 8px;
            width: 180px;
        }

        .dropdown-item {
            padding: 4px 12px;
        }

        .dropdown-item:hover, .dropdown-item:focus {
            background-color: #434857;
        }
    }

    .list-item {
        color: white;

        .user-item__email {
            font-weight: bold;
            font-size: 14px;

            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
        }
        .user-item__name {
            font-size: 12px;

            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-word;
        }
    }
}

.threads-btn {
    background-color: #E96A16;
    color: white;
    font-weight: bold;
    border: none;
}
