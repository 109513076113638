.authentise-login {
  position: absolute;
  width: 85%;
  height: 80%;
  background-color: #2E303B;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  &-flows-logo {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 60px;
  }

  &-form-container {
    width: 100%;
    & > h1 {
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    & > p {
      font-size: 19px;
      font-weight: 100;
      margin-bottom: 25px;
    }
  }

  &-submit-button {
    padding: 3px 10px !important;
    font-size: 16px !important;
    border-radius: 6px !important;
    font-weight: 500 !important;

      &:hover {
        color: #fff!important;
      }
  }

  &-left-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px;
    border-radius: 8px;
    background-image: url(../images/pattern-logo.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 55%;

  }

  &-right-block {
    flex: 1.9; /* Make the right block 60% width */
    padding: 50px;
    position: relative;
    overflow: hidden;
    border-radius: 0 8px 8px 0;

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      filter: blur(1.2px);
      transition: background-image 1s ease-in-out; // Smooth transition for background change
      border-radius: 0 8px 8px 0;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(44, 46, 57, 0.42); /* Adjust opacity and color as needed */
    }

    &-text {
      position: absolute;
      z-index: 1;
      background: linear-gradient(109deg, rgb(46 48 59) -33.38%, #434857 13.47%, rgb(46 48 59 / 78%) 113.8%, rgb(48, 52, 62) 137.87%);
      backdrop-filter: blur(2px);
      color: #fff;
      padding: 20px 25px;
      border-radius: 7px 0 0 7px;
      max-width: 75%;
      right: 0;
      top: 25%;

      &-main {
        font-size: 23px;
        font-weight: 300;
        margin-bottom: 30px;

        &-bold {
          font-weight: 600;
        }
      }

      &-secondary {
        font-size: 16px;
        font-weight: 100;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .authentise-login {
    width: 90%;
    height: 90%;

    &-left-block {
      padding: 30px;
    }

    &-right-block {
      flex: 1.5;
    }

    &-form-container {
      & > h1 {
        font-size: 24px;
      }
      & > p {
        font-size: 16px;
      }
    }

    &-submit-button {
      font-size: 14px !important;
    }

    &-right-block-text {
      padding: 15px 20px;
      &-main {
        font-size: 18px;
      }
      &-secondary {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 400px) and (max-width: 1023px) {
  .authentise-login {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #262830;

    &-flows-logo {
      min-width: 250px;
    }

    &-submit-button {
      padding: 5px 10px!important;
    }

    &-form-container {
      & > h1 {
        font-size: 40px;
      }
      & > p {
        font-size: 20px;
      }

      & > form > input {
        min-height: 45px;
      }
    }

    &-left-block {
      display: flex;
      justify-content: center;
      background-image: unset;
    }

    &-right-block {
      display: none;
    }
  }
}
