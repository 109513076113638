.danfoss-ddw {

  #sidebar {
      background-color: #1a181887;
  
      a > svg > path {
        fill: #fff;
      }
  
      li.active {
        background-color: #fff;
      }

      li.active a {
        color: black;
      }
  
      &li, a {
        color: #fff
      }
    }

    .picky {

      .picky__input--disabled {
        opacity: .7;
        cursor: not-allowed!important;
      }
    }
}