@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@100;900&display=swap');

/* BRAND */
$stanley-brand-primary: #EE3F17;
$stanley-brand-success: #EE3F17;
$stanley-brand-info: #177896;
$stanley-brand-warning: #fd9726;
$stanley-brand-danger: #303030;

$stanley-brand-black: #0D0D0D;
$stanley-brand-orange: #EE3F17;
$stanley-brand-light-gray: #E9EBEE;
$stanley-brand-medium-gray: #747474;
$stanley-brand-dark-gray: #303030;


$white: #fff;
$black: #000;

/* SLATE COLOR PALETTE */
$stanley-slate-50: #ebecee;
$stanley-slate-100: #cbcfd5;
$stanley-slate-200: #acb3ba;
$stanley-slate-300: #828c96;
$stanley-slate-400: #596672;
$stanley-slate-500: #404e5a;
$stanley-slate-600: #323e49;
$stanley-slate-700: #232C36;
$stanley-slate-800: #192028;
$stanley-slate-900: #0F1318;
$stanley-slate-1000: #0a0f14;

$stanley-gray-base: $stanley-slate-200;
$stanley-gray-dark: $stanley-slate-900;
$stanley-gray-darker: $stanley-slate-700;
$stanley-gray: $stanley-slate-500;
$stanley-gray-light: $stanley-slate-200;
$stanley-gray-lighter: $stanley-slate-50;

/* BACKGROUNDS */
$stanley-body-bg: #0D0D0D;
$stanley-light-bg: #FFF;
$stanley-modal-bg: $stanley-body-bg;
$stanley-navbar-inverse-bg: $stanley-brand-black;

/* BORDER */
$stanley-border: $stanley-slate-100;
$stanley-border-radius: 3px;

/* BOX SHADOW */
$stanley-box-shadow: 0 1px 2px 1px rgba(0,0,0,0.16);

/* TEXT COLORS */
$stanley-text-color: $white;
$stanley-text-alt-color: $stanley-slate-500;
$stanley-text-color-inverse: $stanley-gray-lighter;
$stanley-link-color: $stanley-brand-primary;
$stanley-headings-color: $white;
$stanley-headings-small-color: $stanley-gray-dark;

/* SIDEBAR */
$stanley-sidebar-bg: $stanley-brand-black;
$stanley-sidebar-border: $stanley-border;
$stanley-sidebar-active-bg: $stanley-brand-orange;
$stanley-sidebar-color: $white;
$stanley-sidebar-active-color: $white;

/* JUMBOTRON */
$stanley-jumbotron-bg: #303030;
$stanley-jumbotron-border: $white;
$stanley-jumbotron-color: $white;

/* FONT */
$stanley-font-family-sans-serif: "Archivo", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$stanley-font-family-base: $stanley-font-family-sans-serif;
$stanley-font-weight-base: 400;
$stanley-headings-font-weight: $stanley-font-weight-base;

/* INPUTS */
$stanley-input-bg: $stanley-brand-black;
$stanley-input-color: $white;
$stanley-input-border: $stanley-border;

/* BUTTONS */
$stanley-disabled-button-bg: rgba(187, 188, 195, 0.3);
$stanley-disabled-button-color: rgba(0, 0, 0, 0.3);

/* TABLES */
$stanley-table-heading-bg: #303030;
$stanley-table-border-color: $stanley-slate-100;
$stanley-table-hover-bg: $stanley-slate-50;

/* PANELS */
$stanley-panel-default-bg: $stanley-light-bg;
$stanley-panel-default-border: $stanley-border;

/* MODEL LIBRARY CARD */
$stanley-card-bg: $stanley-brand-black;

/* LIST GROUP */
$stanley-list-group-border: $stanley-border;
