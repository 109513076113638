.card-header {
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &-info{
    background-color: #9F86FF;
    color: #fff;
    padding: 1.25rem;
  }
}

.card-body > div > .table-responsive > table > .work-instructions-table > tr {
  color: #cfd2da!important;
}

.card-body-wrapper {
  border: 1px solid #dddddd;

  &-accent {
    border: 1px solid #2596be;
  }

  &-info {
    border: 1px solid #9F86FF !important;
  }

  &-primary {
    border: 1px solid #106cf4;
  }
}


.pd-exp {
  padding: 10px 15px;
}

.primary {
  color: #f5f5f5;
  background-color: #106cf4;
}

.inverse {
  color: #51586a;
  background-color: #f5f5f5;
}

.file-details {
  min-height: 294px;
}

.accent {
  color: #fff;
  background-color: #1CA8DD;
  border-color: #1CA8DD;
}
