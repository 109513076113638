
@keyframes rotateCircleDarkBlue {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes dashCircleDarkBlue {
  from {
    stroke-dashoffset: -337.5; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tickGrayAnimation {
  from {
    stroke-dashoffset: 152; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tickYellowAnimation {
  from {
    stroke-dashoffset: 152; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes tickGreenAnimation {
  from {
    stroke-dashoffset: 152; /* Reduced by 2x */
  }

  to {
    stroke-dashoffset: 0;
  }
}
