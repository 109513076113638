.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 2rem;
  background-color: #30343e;
  border-radius: 4px;
}

.breadcrumb-item {
  &.active {
    color: #ffffff;
  }
}
