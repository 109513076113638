@import './variables.scss';
@import '../dashboard-dark/variables-custom.scss';

body.stanley-x {
  font-family: $stanley-font-family-base !important;
  font-weight: $stanley-font-weight-base !important;
  color: $stanley-text-color !important;
  background-color: $stanley-body-bg !important;
}

.stanley-x {

  .modal.show {
    color: $white!important;
  }

  .nav-item {
    .dropdown-menu {
      background-color: $stanley-brand-black;
      border: 1px solid $white;

      .dropdown-item {
        color: $white;
      }

      .dropdown-item:hover {
        background-color: $stanley-brand-orange;
      }

      .dropdown-item.active {
        background-color: $stanley-brand-orange;
      }
    }
  }

  .btn-close {
    color: $white;
    background-color: $white;
  }

  .form-label {
    color: $white;
  }

  .form-text {
    small {
      color: $white;
    }
  }

  .modal-backdrop.show {
    background-color: $stanley-brand-medium-gray;
  }

  .card-img-top {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .dropdown-toggle {
    color: $white;
  }

  .btn.btn-default {
    color: $white !important;
  }

  .inverse {
    color: $white;
    background-color: $stanley-brand-black;
  }

  .navbar-inverse {
    background-color: $stanley-navbar-inverse-bg;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-size: 18px !important;
    color: $stanley-headings-color;
  }


  hr {
    border-color: $stanley-gray-light;
  }

  .breadcrumb {
    display: none;
  }

  .stanley-x-logo-heading-text {
    font-size: 24px !important;
    margin-top: 21px !important;
    margin-bottom: 10.5px !important;
    color: #0F1318 !important;
  }

  .form-control {
    background-color: $stanley-input-bg !important;
    color: $stanley-input-color !important;
    border-color: $stanley-input-border !important;

    &:disabled {
      background-color: $stanley-input-bg !important;
      color: $stanley-input-color !important;
      border-color: $stanley-input-border !important;
    }

  }

  .form-group {
    margin-bottom: 15px !important;
  }

  .input-group-addon {
    background-color: $stanley-input-bg;
  }

  .input-group-text {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #fff;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
    height: unset;
    background-color: #FAFAFF;
  }

  .jumbotron {
    background-color: $stanley-jumbotron-bg;
    border: 1px solid $stanley-jumbotron-border;
    color: $stanley-jumbotron-color;

    h1 {
      color: $stanley-jumbotron-color;
    }
  }

  .table {
    &-hover {
      > tbody > tr:hover > * {
        color: $white;
        background-color: $stanley-brand-dark-gray;
      }
    }
  }

  table.table-bordered {
    border: none;
  }

  table > tbody > tr {
    color: $white !important;
    border-bottom: 1px solid $stanley-table-border-color !important;
  }

  table > thead {
    text-align: left !important;
  }

  table > tbody > tr > td {
    border: none !important;
  }

  table > tbody > tr:hover {
    background-color: darken($stanley-gray-lighter, 5%) !important;
  }

  table > thead {
    border: none;
  }

  table > thead > tr {
    background-color: $stanley-table-heading-bg !important;
    border-color: $stanley-table-heading-bg !important;

    th {
      border-color: $stanley-body-bg !important;
      color: $stanley-text-color;
    }
  }

  .modal-dialog {
    .modal-header, .modal-footer {
      border-color: darken($stanley-gray-lighter, 5%);
      display: flex;
      align-items: flex-start;
    }

    .modal-footer > .btn {
      padding: 6px 12px !important;
    }

    .modal-content {
      background-color: $stanley-body-bg;
    }

    .picky button {
      background-color: $stanley-input-bg;
      border-color: $stanley-input-border !important;
    }

    .picky {
      color: $white;

      .picky__dropdown {
        background-color: $stanley-brand-black;
      }

      .picky__placeholder {
        color: $white;
      }

    }
  }

  .btn.btn-primary {
    background-color: $stanley-brand-orange !important;
    border-color: $stanley-brand-orange !important;
  }

  .btn.btn-primary:focus {
    outline-color: $stanley-slate-700;
  }

  .btn.btn-success {
    background-color: $stanley-brand-success !important;
    border: 1px solid $white !important;
    color: $white;
  }

  .btn.btn-danger {
    background-color: $stanley-brand-danger !important;
    border-color: darken($stanley-brand-danger, 5%) !important;
    color: $white;
  }

  .btn.btn-default {
    background-color: transparent !important;
    border-color: darken($stanley-gray-lighter, 5%) !important;
    color: $white;

    &:active {
      box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
    }
  }

  .btn-toolbar > button {
    padding: 6px 12px !important;
  }

  .btn-upload {
    padding: 6px 12px !important;
  }

  .btn.dark-gray-btn {
    background-color: $stanley-slate-500 !important;
    border-color: $stanley-slate-500 !important;
    padding: 10px 16px !important;
    font-size: 18px !important;
    border-radius: 6px !important;
    color: #fff !important;
    width: 100% !important;
  }

  .tabs-container > .nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
    color: #fff !important;

    &:hover {
      background-color: #cfd2da !important;
    }
  }

  .tabs-container > .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: #cfd2da !important;
    background-color: #cfd2da !important;
    color: #51586a !important;
  }

  .btn[disabled] {
    background-color: $stanley-disabled-button-bg !important;
    border-color: $stanley-disabled-button-bg !important;
    color: $stanley-brand-light-gray;
  }

  .panel.panel-primary {
    border: none;
    box-shadow: none;

    .panel-heading {
      border: none;
      background-color: inherit;
      color: $stanley-text-color;
      font-weight: 700;
      font-size: 16px;
    }

    .panel-body {
      background-color: $stanley-light-bg;
      border-radius: 6px;
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.16);
    }
  }

  .panel.panel-default {
    border: none;
    box-shadow: none;

    .panel-heading {
      border: none;
      background-color: inherit;
      color: $stanley-text-color;
      font-weight: 700;
      font-size: 16px;
    }

    .panel-body {
      background-color: $stanley-light-bg;
      border-radius: $stanley-border-radius;
      box-shadow: $stanley-box-shadow;
    }
  }

  .list-group {
    .list-group-item {
      border-color: $stanley-border;
      color: $stanley-text-color;
    }


    a.list-group-item:hover, div.list-group-item:hover, a.list-group-item:focus, div.list-group-item:focus, button.list-group-item:hover {
      background-color: #303030;
    }
  }

  .list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: $stanley-slate-50;
    color: $stanley-text-alt-color;
  }

  .card {
    background-color: $stanley-card-bg !important;
    border: 1px solid $white;
    color: $white;
    border-radius: 2px;
  }

  .card-header {
    border: none;
    color: $white;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 800;
  }

  .card-body {
    .img-thumbnail {
      padding: 0;
    }
  }

  .dropdown-item {
    font-size: 14px
  }

  .card-title {
    color: $stanley-text-color;
    font-weight: bold;
  }

  .part-analysis-scenarios {
    .panel-body {
      padding: 0;
    }
  }

  .progress {
    background-color: $stanley-slate-100;

    .progress-bar {
      background-color: $stanley-slate-300;
    }
  }

  .fa-icon-style {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .stanley-x-forgot-password {
    color: $stanley-slate-500;
  }

  .modal.show {
    color: $stanley-gray-lighter !important;
  }
  .modal small {
    color: $white !important;
  }
}

.stanley-x-primary {
  color: $white !important;
  background-color: $stanley-brand-primary !important;
  border-color: $stanley-brand-primary !important;

  &:hover,
  &:hover:active,
  &:hover:focus,
  &:focus,
  &:active {
    color: $white !important;
    background-color: $stanley-slate-600 !important;
    border-color: $stanley-slate-600 !important;
  }
}

.stanley-x-secondary {
  color: $stanley-gray !important;
  background-color: $stanley-body-bg !important;
  border-color: $stanley-slate-100 !important;

  &:hover,
  &:hover:active,
  &:hover:focus,
  &:focus,
  &:active {
    color: $stanley-gray !important;
    background-color: $stanley-slate-100 !important;
    border-color: $stanley-slate-100 !important;
  }
}

.authentise-pdm {

  #sidebar {
    background-color: #1a181887;

    a > svg > path {
      fill: #fff;
    }

    li.active {
      background-color: #282a2e;
    }

    &li, a {
      color: #fff
    }
  }

  .fade.in {
    overflow-y: auto;
  }

  .navbar {
    background-color: #1a1c22;
  }

  .navbar-impersonate {
    background-color: #203455;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
  }

}

.stanley-x-badge-secondary {
  background-color: #cfd2da !important;
  color: #222429 !important;
}

.stanley-x-badge-secondary-sm {
  background-color: #cfd2da !important;
  color: #222429 !important;
  font-size: 75% !important;
}

.stanley-x-order_btn-container {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
}

.stanley-x-order_now-btn {
  position: sticky;
  background-color: $stanley-brand-orange !important;
  border-color: $stanley-brand-orange !important;
}


