@import '../dashboard-dark/variables-custom';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $gray-darker;
  background-clip: border-box;
  border-radius: 6px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.16);
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2em;
  list-style-type: none;
  padding: 0;
}

.modal-dialog {
  .card-title {
    color: #fff;
  }
}
