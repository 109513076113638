.form-label {
  width: auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  min-height: 35px;
  padding: 6px 12px!important;
  font-size: 14px!important;
  color: #ffffff!important;
  background-color: #434857!important;
  border: 1px solid #434857!important;
  border-radius: 4px;
  appearance: auto;
  transition: none;

  &:disabled {
    background-color: #30343e!important;
    cursor: not-allowed;
  }
}

.form-control-select {
  max-width: 250px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-label {
  display: inline-block;
  max-width: 100%;
  font-weight: bold;
}

.input-group {
  width: 100%;

  .input-group-label-light{
    background-color: #eee;
    color: #51586a;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.form-inline {
  @media (min-width: 768px) {
    // Inline-block all the things for "inline"
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    // In navbar-form, allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-static {
      display: inline-block;
    }

    .input-group {
      display: inline-table;
      vertical-align: middle;

      .input-group-addon,
      .input-group-btn,
      .form-control {
        width: auto;
      }
    }

    // Input groups need that 100% width though
    .input-group > .form-control {
      width: 100%;
    }

    .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .radio,
    .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }
    }
    .radio input[type='radio'],
    .checkbox input[type='checkbox'] {
      position: relative;
      margin-left: 0;
    }

    // Re-override the feedback icon.
    .has-feedback .form-control-feedback {
      top: 0;
    }
  }
}
