/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */

// ==========================================================================
// Print styles.
// Inlined to avoid the additional HTTP request: h5bp.com/r
// ==========================================================================

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
  }

  html body {
    margin: 0 !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  // Don't show links that are fragment identifiers,
  // or use the `javascript:` pseudo protocol
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Bootstrap specific changes start

  // Bootstrap components
  .navbar {
    display: none;
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

  .col-lg-12 {
    padding: 0 !important;
  }

  .hide-on-print {
    display: none;
  }

  .sticker {
    padding: 5%;

    page-break-inside: avoid;
    page-break-after: always;

    font-size: 5.5vmin;
    font-weight: 500;
    line-height: 1.5;

    .qr {
      display: block;
      width: 40%;
      margin: 1em auto;

      a[href]:after {
        content: none !important;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .barcode {
      display: block;
      text-align: center;
      margin: 1em auto;

      a[href]:after {
        content: none !important;
      }

      svg > rect {
        fill: #fff !important;
      }

      svg > g {
        fill: #000 !important;
      }
    }

    .only-printable {
      display: initial !important;
    }

    footer {
      font-weight: normal;

      .logo {
        vertical-align: middle;
        width: 1.2em;
        height: 1.2em;
      }
    }

    .non-printable {
      display: none;
    }

    &:first-child {
      border-top: 0;
    }
  }

  .s-alert-wrapper {
    display: none;
  }
  .order-packing-slip {
    display: initial !important;
  }
  // Bootstrap specific changes end
}

.sticker {
  .only-printable {
    display: none;
  }
}

.order-packing-slip {
  display: none;
  &__sticker {
    float: right;
    font-size: 15px;
    text-transform: uppercase;

    & .logo {
      vertical-align: middle;
      width: 1.2em;
      height: 1.2em;
    }
  }
}
