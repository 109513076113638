@import '../dashboard-dark/variables-custom';

.react-calendar-timeline .rct-header-root {
  background-color: $gray-darker;
  border: 1px solid $gray-dark;
  border-radius: 4px 4px 0 0;
}

.react-calendar-timeline .rct-dateHeader {
  background-color: $gray;
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
}

.react-calendar-timeline .rct-calendar-header {
  border: 1px solid $gray;
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: $gray-darker;
}

.react-calendar-timeline .rct-sidebar {
  background-color: $gray-darker;
  border-top: 1px solid $gray;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid $gray;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  background-color: $gray-base;
}

.rct-vertical-lines {
  .rct-day-6, .rct-day-0 {
    background: $gray !important;
    opacity: 0.25;
  }
}

.react-calendar-timeline .rct-item .rct-item-content {
  padding: 0;
  padding-left: 3px;
  overflow: hidden;
  display: block;
}

.react-calendar-timeline .work-schedule-horizontal-line {
  background: repeating-linear-gradient(
  45deg,
  #242831,
  #32363f 10px,
  #6f6565 10px,
  #6f6565 20px)!important;
}
