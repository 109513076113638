.PiecesCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #3B3F4B;
  border: 1px solid #434857;
  border-radius: 5px;
  padding: 9px 15px;

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    & > svg {
      font-size: 15px;
    }
  }

  &List {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) !important;

    &Cards {
      margin-top: 15px;
    }

    &Location {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }

  &PieceInfo {
    display: flex;
    max-height: 37px;
    margin-bottom: 10px;

    &Data {
      max-width: 130px;
    }
  }

  &Piece {

    &Status {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: right;
      white-space: nowrap;
    }

    &Name {
      max-width: 130px;
    }

    &Type {
      width: 130px;
      max-width: 130px;
      font-size: 12px;
    }
  }

  &LeftData {
    display: grid;
    justify-content: start;
    align-content: space-around;
  }

  &RightData {
    display: grid;
    justify-content: end;
    align-content: space-between;

    &Card {
      background: #434857;
      padding: 10px;
      border-radius: 5px;
      width: 200px;
      max-width: 200px;

      &OrderPriority {
        .badge-sm {
          font-size: 10px !important;
        }
      }

      &:first-child {
        margin-bottom: 10px;
      }

      &NoModel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 125px;
        max-width: 125px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        color: #909090;
        background-color: #d1d1d1;
        font-size: 14px;
      }

      &Model {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;

        &Dimensions {
          font-size: 8px !important;
          text-align: center;
          margin-bottom: 0 !important;
        }

        &Container {
          display: grid;
          justify-content: center;
          width: 143px;
          max-width: 143px;
        }

        &Button {
          width: 135px;
          max-width: 135px;
          height: 129px;
          overflow: hidden;
          position: relative;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s;

          &IconContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #cfcfcf00;
            transition: opacity 0.3s ease-in-out;
          }

          &Icon {
            transition: 0.3s;
            color: rgba(255, 255, 255, 0.26);
            font-size: 36px;
          }

        }
      }
    }
  }
}

.PiecesCardRightDataCardModelButton:hover .PiecesCardRightDataCardModelButtonIcon {
  color: rgb(255 255 255 / 72%);
  font-size: 40px;
}


.PiecesCardRightDataCardModelButton:hover .PiecesCardRightDataCardModelButtonIconContainer {
  background-color: #68686896;
}
