.custom_field_url_wrapper.valid {
  @media (max-width: 420px) {
    input {
        display: none;
    }
    .input-group-text {
        padding: 9px 12px !important;
    }
  }
}

.custom_field_url_wrapper.invalid {
    @media (max-width: 420px) {
      display: none !important;
    }
}

.custom_field_url_warning {
    @media (max-width: 420px) {
      display: none !important;
    }
}
