.container {
  padding-top: 10px;
  padding-bottom: 20px;

  &-fluid {
    padding: 10px 18px 0px;
  }
}

.navbar > .container-fluid {
  padding-top: 0;
  padding-left: 18px;
}
