$red: #dc3545 !default;

.multiple-upload-modal.show {
  z-index: 1080 !important;

  .picky__dropdown {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .picky__dropdown > div:not(.picky__filter) {
    padding-left: 6px;
  }

  .picky__input--disabled {
    background-color: #f5f5f5 !important;
    opacity: .7;
    cursor: not-allowed;
  }
}

.file-list-item {
  justify-content: space-between;
}

.exclamation-mark {
  color: white;
  background: $red;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  padding-top: 3px;
}

.error-outline {
  outline: 1px solid $red;
  box-shadow: 0 0 5px 2px rgba(220, 53, 69, 0.4);
}

.uploaded-file-checkbox {
  max-width: calc(100% - 30px);
  label {
    word-break: break-all;
  }
}

.tooltip-error {
  .tooltip-inner {
    background: $red;
    color: white;
  }

  &.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $red;
  }
}
