@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* BRAND */
$hawking-brand-primary: #404E5A;
$hawking-brand-success: #24882b;
$hawking-brand-info: #177896;
$hawking-brand-warning: #fd9726;
$hawking-brand-danger: #d92231;

$white: #fff;
$black: #000;

/* SLATE COLOR PALETTE */
$hawking-slate-50: #ebecee;
$hawking-slate-100: #cbcfd5;
$hawking-slate-200: #acb3ba;
$hawking-slate-300: #828c96;
$hawking-slate-400: #596672;
$hawking-slate-500: #404e5a;
$hawking-slate-600: #323e49;
$hawking-slate-700: #232C36;
$hawking-slate-800: #192028;
$hawking-slate-900: #0F1318;
$hawking-slate-1000: #0a0f14;

$hawking-gray-base: $hawking-slate-200;
$hawking-gray-dark: $hawking-slate-900;
$hawking-gray-darker: $hawking-slate-700;
$hawking-gray: $hawking-slate-500;
$hawking-gray-light: $hawking-slate-200;
$hawking-gray-lighter: $hawking-slate-50;

/* BACKGROUNDS */
$hawking-body-bg: #f2f2f9;
$hawking-light-bg: #FAFAFF;
$hawking-modal-bg: $hawking-body-bg;
$hawking-navbar-inverse-bg: $hawking-slate-600;

/* BORDER */
$hawking-border: $hawking-slate-100;
$hawking-border-radius: 6px;

/* BOX SHADOW */
$hawking-box-shadow: 0 1px 2px 1px rgba(0,0,0,0.16);

/* TEXT COLORS */
$hawking-text-color: $hawking-gray-dark;
$hawking-text-alt-color: $hawking-slate-500;
$hawking-text-color-inverse: $hawking-gray-lighter;
$hawking-link-color: $hawking-brand-primary;
$hawking-headings-color: $hawking-gray-dark;
$hawking-headings-small-color: $hawking-gray-dark;

/* SIDEBAR */
$hawking-sidebar-bg: $hawking-light-bg;
$hawking-sidebar-border: $hawking-border;
$hawking-sidebar-active-bg: $hawking-slate-50;
$hawking-sidebar-color: $hawking-text-color;
$hawking-sidebar-active-color: $hawking-text-alt-color;

/* JUMBOTRON */
$hawking-jumbotron-bg: $hawking-light-bg;
$hawking-jumbotron-border: $hawking-border;
$hawking-jumbotron-color: $hawking-slate-300;

/* FONT */
$hawking-font-family-sans-serif: "Roboto Condensed", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$hawking-font-family-base: $hawking-font-family-sans-serif;
$hawking-font-weight-base: 400;
$hawking-headings-font-weight: $hawking-font-weight-base;

/* INPUTS */
$hawking-input-bg: $hawking-light-bg;
$hawking-input-color: $hawking-text-color;
$hawking-input-border: $hawking-border;

/* BUTTONS */
$hawking-disabled-button-bg: rgba(187, 188, 195, 0.3);
$hawking-disabled-button-color: rgba(0, 0, 0, 0.3);

/* TABLES */
$hawking-table-heading-bg: #E6E6EE;
$hawking-table-border-color: $hawking-slate-100;
$hawking-table-hover-bg: $hawking-slate-50;

/* PANELS */
$hawking-panel-default-bg: $hawking-light-bg;
$hawking-panel-default-border: $hawking-border;

/* MODEL LIBRARY CARD */
$hawking-card-bg: $hawking-light-bg;

/* LIST GROUP */
$hawking-list-group-border: $hawking-border;
