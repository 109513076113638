.HalfCircleProgressBar {
  position: relative;
  text-align: center;

  &Overflow {
    position: relative;
    overflow: hidden;
    width: 56px; height: 32px;
    margin-bottom: -16px;
  }

  &Line {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px; height: 56px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 5px solid #434857;
    border-bottom-color: #5EC692;
    border-right-color: #5EC692;
  }

  &Percentage {
    font-size: 11px;
  }
}


.LinearProgressBar {
  position: relative;
  background: #3B3F4B;
  border-radius: 15px;
  width: 100%;
  height: 15px;

  &Line {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #4276DD;
    border-radius: 15px;
    height: 100%;
  }

  &Percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    font-size: 10px;
    font-weight: 400;
    z-index: 1;
  }
}

.upload-file-progress-bar {
  background: #3eba00;
  border-radius: 5px;
  height: 3px;
}
